<template>
  <div>
    <b-navbar id="topheader" toggleable="md" type="dark">
      <b-button 
      id="but-toggle" 
      size="sm" 
      v-b-toggle.sidebar-1 
      variant="dark"
      @click.prevent="sidebarToggle"
      >
      <b-icon icon="arrow-left-right" variant="light" font-scale="1.2" aria-hidden="true"></b-icon></b-button>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item href="https://speechor.com/Askus" target="_blank" ><b-icon icon="life-preserver" variant="warning"  font-scale="1.3" aria-hidden="true"></b-icon> <span style="color: #ffffff; font-size: 1em;">Support</span></b-nav-item>
          <b-nav-item href @click.prevent="logOut"><b-icon icon="box-arrow-right" variant="danger"  font-scale="1.3" aria-hidden="true"></b-icon> <span style="color: #ffffff; font-size: 1em;">LOGOUT</span></b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    
  </div>
</template>

<script>
export default {
  data() {
    return {
      
    };
  },
  mounted(){
    this.$store.dispatch("initialize");
  },
  methods: {
    sidebarToggle(){
      this.$store.dispatch("sidebarToggle");
    },
     logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    }
  },
  
};
</script>

<style scoped>
#nav a.router-link-exact-active {
  color: white;
}

#topheader{
  background-color: #2b2b2b;
  padding-left: 0px;
}

#nav-brand{
  margin-left: 30px;
}

</style>