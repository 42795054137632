<template>
  <b-container fluid>
    <b-row class="justify-content-center mx-0">
      <h3 class="sectitle">Speechor Video Transcriber</h3>
    </b-row>
    <b-row class="justify-content-center mx-0"> 
      <h4 class="whitetitle">Transcribe Any Video With Animated Text</h4>
    </b-row>
    <b-row class="justify-content-center mx-0"> 
      <h4 class="whitetitle">Speed up your content Creation!</h4>
    </b-row>
    <b-row class="justify-content-center" no-gutters>
        <b-col xs="12" md="8" class="p-2">
          <b-embed 
          type="video" 
          aspect="16by9"
          controls 
          autoplay 
          poster="https://speechormanager.univerhome.com/uploads/media/transcriber_thimb.jpg">    
          <source src="https://speechormanager.univerhome.com/uploads/media/transcriber-19-6.mp4" type="video/mp4">
          </b-embed>
        </b-col>
    </b-row>
    <b-row class="justify-content-center mx-0">
      <h3 class="sectitle">Subscription Pricing</h3>
    </b-row>
    <b-row class="justify-content-center" no-gutters>
        <div class="text-secondary">
          <b-form-checkbox v-model="checkedYear" name="check-button" @input="onCheckedYear" switch>
            <span style="color: #ffffff; font-size: 1em;">Annual pricing</span>
          </b-form-checkbox>
        </div>
    </b-row>
    <b-row class="justify-content-center mb-3" no-gutters>
        <b-col v-bind:key='product.id' v-for="product in getMonthlyPrices" xs="12" md="4" class="p-2 d-flex justify-content-center">
          <b-card
          
          no-body
          style="width: 100%; background-color: #7930ff;"
          
          >
            <template #header>
              <h6 v-show="!checkedYear" class="mb-0 text-center" style="color: #ffffff; font-size: 1.9em;">{{product.name.replace('Regular','')}}</h6>
              <h4 v-show="!checkedYear" class="m-2 text-center" style="color: #ffffff; font-size: 2em;">${{product.unit_price.amount/100}}</h4>
              <h6 v-show="checkedYear" class="mb-0 text-center" style="color: #ffffff; font-size: 1.9em;">{{getAnnuallyPrices[getMonthlyPrices.indexOf(product)].name.replace('Regular','')}}</h6>
              <h4 v-show="checkedYear" class="m-2 text-center" style="color: #ffffff; font-size: 2em;">${{getAnnuallyPrices[getMonthlyPrices.indexOf(product)].unit_price.amount/100}}</h4>
              <p v-show="checkedYear" class="m-2 text-center" style="color: #ffffff; font-size: 1.2em;">(${{getAnnuallyPrices[getMonthlyPrices.indexOf(product)].unit_price.amount/1200}}/month)</p>
            </template>
            <b-list-group  flush>
                <b-list-group-item v-if="product.trial_period!==null" style="background-color: #5C66F7; color: #ffffff; font-size: 0.9em;">
                  <p class="mb-0 text-center" >Trial Period</p>
                  <h6 class="mb-0 text-center" style="color: #ffffff; font-size: 1.4em;">Free {{product.trial_period.frequency}} {{product.trial_period.interval}}s trial</h6>
                </b-list-group-item>
                <b-list-group-item style="background-color: #5C66F7; color: #ffffff; font-size: 0.8em;">
                    <p class="mb-0 text-center">Monthly Videos</p>
                    <h6 class="mb-0 text-center" style="color: #ffffff; font-size: 1.4em;">{{product.packVideoLimit}}</h6>
                </b-list-group-item>
                <b-list-group-item style="background-color: #5C66F7; color: #ffffff; font-size: 0.8em;">
                    <p class="mb-0 text-center">Add your Logo</p>
                    <h6 class="mb-0 text-center" style="color: #ffffff; font-size: 1.4em;">{{product.logo}}</h6>
                </b-list-group-item>
                <b-list-group-item style="background-color: #5C66F7; color: #ffffff; font-size: 0.8em;">
                    <p class="mb-0 text-center">Ai Script</p>
                    <h6 class="mb-0 text-center" style="color: #ffffff; font-size: 1.4em;">{{product.aiscripting}}</h6>
                </b-list-group-item>
                <b-list-group-item style="background-color: #5C66F7; color: #ffffff; font-size: 0.8em;">
                    <p class="mb-0 text-center">Ai Voice-over</p>
                    <h6 class="mb-0 text-center" style="color: #ffffff; font-size: 1.4em;">{{product.aivoice}}</h6>
                </b-list-group-item>
                <b-list-group-item style="background-color: #5C66F7; color: #ffffff; font-size: 0.8em;">
                    <p class="mb-0 text-center">Background Music</p>
                    <h6 class="mb-0 text-center" style="color: #ffffff; font-size: 1.4em;">{{product.backmusic}}</h6>
                </b-list-group-item>
                
            </b-list-group>
            <b-card-body>
                <b-button 
                v-if="getMonthlyPrices.indexOf(product)==1"
                class="w-100 mt-2" 
                size="col-md-4"           
                squared 
                :id="'boost-'+getMonthlyPrices.indexOf(product)" 
                variant="warning"
                @click.prevent="ordernow(product.id, getMonthlyPrices.indexOf(product))"
                >Order Now</b-button>

                <b-button 
                v-else
                class="w-100 mt-2" 
                size="sm"           
                squared 
                :id="'boost-'+getMonthlyPrices.indexOf(product)" 
                variant="primary"
                @click.prevent="ordernow(product.id, getMonthlyPrices.indexOf(product))"
                >Order Now</b-button>
            </b-card-body>
        </b-card>
        </b-col>   
    </b-row>

  </b-container>
</template>


<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      cancelUrl: 'https://speechorapp.univerhome.com/Subscription_Video_Transcriber',
      checkoutPath: 'https://speechor.com',
      checkedYear:false,
      billingCycle: 'month',
    };
  },
  components: { 
  },

  computed: {
    ...mapGetters(["getMonthlyPrices", "getAnnuallyPrices"]),

  },

  mounted() {
      this.$store.dispatch("updateVideoSubscriptionStatus");
      if(this.$store.state.isVideoSubscribed == true){
        this.$router.push('/Transcriber');
      }

      this.$store.dispatch("getPaddlePrices", {router: this.$router, moduleReference: "Transcriber"}); 
      
  },
  methods:{
    customOffer(){
      window.location.replace(this.checkoutPath+'/Askus');
    },

    onCheckedYear(){

      if (this.checkedYear){
        this.billingCycle = 'year';
      } else {
        this.billingCycle = 'month';
      }

    },

    ordernow(id, index){
      //console.log(id, index);
      window.location.replace(this.checkoutPath+'/Checkout?mp='+id+'&ap='+this.$store.state.paddleAnnuallyPrices[index].id+'&bc='+this.billingCycle+'&curl='+this.cancelUrl); 
      }  
  },

  
};
</script>
<style scoped>
  .sectitle{
    color: #ffba00;
    text-align: center;
   }
 
 .whitetitle{
  color: #fff;
  text-align: center;
 }
</style>